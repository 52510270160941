import { mapGetters, mapActions, mapMutations } from 'vuex';
import templateEngine from '../../../../mixins/templateEngine';
import humanize from '../../../../mixins/humanize';
import { get } from '../../../../helpers/cookie';
import _laxios from '../../../../store/_laxios';

export default {
  data() {
    return {
      showMessage: false,
      hasApplePayBtn: false,
      pseudoInput: true,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [templateEngine, humanize],
  computed: {
    ...mapGetters({
      isWebView: 'shared/device/isWebView',
    }),
    humanizedValueAmount() {
      return this.humanizeNumber(this.valueAmount);
    },
    valueAmount: {
      get() {
        return this?.data?.payDefaultValue >= 0 ? this.data.payDefaultValue : 100;
      },
      set(val) {
        if (this.data) {
          this.data.payDefaultValue = val;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      startApplePaymentSession: 'others/startApplePaymentSession',
      makeOrder: 'others/makeOrder',
    }),
    ...mapMutations({
      donationsLoggerData: 'donation/donationsLoggerData',
    }),
    showCongratsPopup() {
      this.showMessage = true;
    },
    close() {
      this.showMessage = false;
      this.valueAmount = 100;
    },
    linkPayment(response, paymentMethod) {
      if (paymentMethod === 'p24') {
        return this._result(response, 'payData.invoiceData.privatPayUrl');
      }
      return `https://oplaty.ria.com/donations/order/${this._result(response, 'orderId')}`;
    },
    createOrder(paymentMethod) {
      this.showMessage = true;
      const clickAction = paymentMethod === 'p24' ? 5 : 7;
      this.sendDonationsLogs({ click_action: clickAction });
      const options = {
        services: [{
          name: 'donation',
          donation: this.valueAmount,
        }],
        paymentMethod,
      };

      if (this.isDesktop) {
        const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
        this.makeOrder(options)
          .then((response) => {
            billingWindow.location.href = this.linkPayment(response, paymentMethod);
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
            billingWindow.close();
          });
      } else {
        this.makeOrder(options)
          .then((response) => {
            window.location = this.linkPayment(response, paymentMethod);
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
          });
      }
    },
    btnGPay() {
      this.sendDonationsLogs({ click_action: 6 });
      this.showMessage = true;
    },
    changeAmount() {
      this.pseudoInput = false;
    },
    handleFocusout() {
      this.pseudoInput = true;
      this.sendDonationsLogs({ click_action: 4 });
    },
    sendDonationsLogs(logData) {
      this.donationsLoggerData({ action_block: 1, price: this.valueAmount, ...logData });
    },
    async getApplePayBtnInitData(body) {
      try {
        const res = await _laxios.getPaymentData.request({ data: { ...body } })
          .then((response) => response)
          .catch((e) => { console.error('getPaymentData', e.message); });

        return res;
      } catch (e) {
        console.error('getPaymentData', e);
        return null;
      }
    },
    async applePaySubmit() {
      const body = {
        type: 'apple_pay',
        services: [{
          name: 'donation',
          donation: this.valueAmount,
        }],
      };

      const actionData = await this.getApplePayBtnInitData(body);

      if (!actionData) return;

      // TODO: Waiting for Apple to save data from their side
      setTimeout(() => {
        const session = new ApplePaySession(1, actionData.data);

        // Відправка сертифікату Merchant Identity Certificate на бекенд (запит на валідацію)
        session.onvalidatemerchant = ({ validationURL }) => {
          const data = {
            validationURL,
            type: actionData.type,
            merchantIdentifier: actionData.data.merchantIdentifier,
          };

          this.startApplePaymentSession(data)
            .then(({ merchantSession }) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch(() => {
              session.abort();
            });
        };

        // Відправка запиту на оплату
        session.onpaymentauthorized = (event) => {
          const data = {
            services: body.services,
            // TODO: delete userForcesAction after test
            userForcesAction: 1,
            paymentMethod: 'APay',
            paymentData: event.payment?.token?.paymentData || {},
            paymentTypeId: actionData?.paymentType,
            paymentCost: actionData?.sum,
          };

          this.makeOrder(data)
            .then((response) => {
              if (response?.orderId) {
                session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                this.showCongratsPopup();
              } else {
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
              }
            })
            .catch((e) => {
              session.abort();
            });
        };

        session.begin();
      }, 300);
    },
  },
  mounted() {
    if (!window.googlePayScriptLoaded) {
      const googlePayScript = document.createElement('script');
      googlePayScript.src = 'https://pay.google.com/gp/p/js/pay.js';
      googlePayScript.async = true;
      googlePayScript.onload = () => {
        window.googlePayScriptLoaded = true;
      };
      googlePayScript.onerror = (error) => {
        console.error('Error loading Google Pay script:', error);
      };
      document.head.appendChild(googlePayScript);
    }

    const hasTestCookie = get('test_new_features') === '5003';
    this.hasApplePayBtn = Boolean(window?.ApplePaySession?.canMakePayments() && hasTestCookie);
  },
  components: {
    GooglePay: () => import('./GooglePay/index.vue'),
  },
};
