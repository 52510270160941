import { mapMutations } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [require('../../../mixins/templateEngine')],
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    ...mapMutations({
      donationsLoggerData: 'donation/donationsLoggerData',
    }),
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
