export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [require('../../../mixins/templateEngine')],
  computed: {
    header() {
      return this?.data?.header;
    },
  },
};
