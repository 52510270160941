import { mapMutations } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [require('../../../mixins/templateEngine')],
  methods: {
    ...mapMutations({
      donationsLoggerData: 'donation/donationsLoggerData',
    }),
  },
};
