import { mapGetters } from 'vuex';
export default {
    components: {
        StatisticDonations: () => import('../../StatisticDonations/index.vue'),
        LastDonations: () => import('../../LastDonations/index.vue'),
    },
    computed: {
        ...mapGetters({
            donationsData: 'donation/donationsData',
            donationsLoggerData: 'donation/donationsLoggerData',
        }),
        lastDonations() {
            return this?.donationsData?.lastDonations || {};
        },
        statisticDonations() {
            return this?.donationsData?.statisticDonations || {};
        },
    },
};

